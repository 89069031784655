/*
 * Copyright 2023 Whitney Hunter
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.header {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

h1 {
    border-bottom: crimson 1px solid;
}

#toc_container {
    background: #f9f9f9 none repeat scroll 0 0;
    display: table;
    font-size: 95%;
    width: 100%;
}

#toc_container li, #toc_container ul, #toc_container ul li{
    list-style: outside none none !important;
}
